<template>
  <div class="property-wrapper" v-if="control">
    <ControlBaseProperties v-model="control" :hasExpression="true" />
    <ControlSpecificPanel :control="control">
      <DataStateListForm
        v-if="stateImages"
        v-model="dataSource"
        title="synoptic.image_list"
        :hasImage="true"
        :equipmentData="equipmentData"
      />
    </ControlSpecificPanel>
  </div>
</template>
<script>
import StateControl from "@/components/synoptic/property-editor/controls/state-control.vue";
export default {
  name: "StatusIcon",
  extends: StateControl
};
</script>
